import { j as n } from "./jsx-runtime-B6kdoens.js";
import { _ as i } from "./index-28E3tTLh.js";
import { forwardRef as l, createElement as c } from "react";
import { $ as m } from "./index-CZBInFmv.js";
import { c as u } from "./utils-CJ9afRe1.js";
import { c as x } from "./index-BV2fynjD.js";
const b = /* @__PURE__ */ l((t, r) => /* @__PURE__ */ c(m.label, i({}, t, {
  ref: r,
  onMouseDown: (e) => {
    var a;
    (a = t.onMouseDown) === null || a === void 0 || a.call(t, e), !e.defaultPrevented && e.detail > 1 && e.preventDefault();
  }
}))), p = b, $ = x(
  "text-sm leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70",
  {
    variants: {
      truncate: {
        true: "truncate"
      },
      required: {
        true: "truncate"
      },
      variant: {
        default: "text-primary",
        secondary: "text-gray-neutral-70",
        destructive: "text-red-500"
      },
      fontSize: {
        xs: "text-xs",
        sm: "text-sm",
        md: "text-base",
        lg: "text-lg",
        xl: "text-xl"
      },
      fontWeight: {
        normal: "font-normal",
        default: "font-medium",
        bold: "font-bold"
      },
      defaultVariants: {
        variant: "default",
        fontWeight: "default",
        truncate: !1,
        required: !1,
        fontSize: "sm"
      }
    }
  }
), g = l(({ className: t, variant: r, fontWeight: e, truncate: a, fontSize: s, required: f, ...o }, d) => /* @__PURE__ */ n.jsxs(
  p,
  {
    ref: d,
    className: u($({ variant: r, fontSize: s, truncate: a, fontWeight: e, className: t })),
    ...o,
    children: [
      o.children,
      f && /* @__PURE__ */ n.jsx("span", { className: "text-blue-100", children: "*" })
    ]
  }
));
g.displayName = "Label";
export {
  g as L
};
